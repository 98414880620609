.fa,
.fas,
.far,
.fal,
.fab {
  font-family: "Font Awesome 5 Free";
}

.footerstyle {
  font-family: "Proxima Nova", sans-serif;
  position: relative;
  display: block;
  width: 100%;
  bottom: 0;
  background-color: #173a52;
  color: #d5d5d5;
  text-align: center;
  padding: 20px;
  margin-top: 60px;
  padding-bottom: 60px;
}

#footer-content {
  font-size: 20px;
}

#footer-content > a {
  color: rgba(255, 255, 255, 0.95);
  transition: all 0.2s ease;
}

#footer-content > a:hover {
  opacity: 0.75;
}

.smallbutton1 {
  width: 20px;
  margin: 10px;
}

.smallbutton1:hover {
  opacity: 0.75;
}

.picture {
  width: auto;
  height: 100px;
  margin: 20px;
  margin-bottom: 40px;
}

div#copyright {
  margin-top: 20px;
  margin-bottom: -18px;
}

#copyright span {
  color: rgba(249, 250, 252, 0.75);
  font-size: 12px;
  font-weight: normal;
  font-family: "Proxima Nova", sans-serif;
}

.icon {
  font-size: 20px;
  color: white;
  width: 25px;
}

.logo {
  display: inline-block;
  text-align: center;
  width: 60px;
  height: auto;
  margin-bottom: 20px;
}

.logo svg {
  display: inline-block;
}

@media (min-width: 768px) {
  .icon {
    font-size: 30px;
    width: 40px;
  }
  .logo {
    width: 80px;
  }
  #copyright span {
    font-size: 0.9em;
  }
}
