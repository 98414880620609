@import "../../_defs";

.container {
  padding: 21px 24px;
  background-color: $darker_teal;
  margin-bottom: 24px;
  @include border();

  > h2 {
    @include header1();
    font-size: 17px;
    color: $bright_teal;
  }
}

@media (min-width: 992px) {
  .container {
    padding: min(8%, 48px) min(8%, 64px);

    > h2 {
      @include header1(false);
      font-size: min(2rem, 2.7vw);
      line-height: 41px;
      color: $bright_teal;
    }
  }
}
