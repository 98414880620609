.container {
    display: flex;
    justify-content:center;
    width: 100%;
}

@media (min-width: 992px) {
    .container {
        display: none;
    }
  }