@import "./defs.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: $darker_teal;

  > h2 {
    margin-top: 53px;
    text-align: center;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: white;
  }
  > h3 {
    text-align: center;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: white;
    > a {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: white;
    }
  }
  .errorCode {
    margin: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .errorCode {
    width: 80%;
  }
}
