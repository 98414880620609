.container {
  padding: 24px;
  width: 100%;
  z-index: 999;
  background: #173a52;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  > p {
    margin-bottom: 0px;
  }
}

.hamburger {
  width: 30px;
  height: 30px;
  color: #ffffff;
}

.hamburger:hover {
  cursor: pointer;
}

.logo {
  width: 30px;
  height: 30px;
  animation: fadeIn 200ms;
  -webkit-animation: fadeIn 200ms;
  -moz-animation: fadeIn 200ms;
  -o-animation: fadeIn 200ms;
  -ms-animation: fadeIn 200ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mlhbanner {
  width: 60px;
  position: fixed;
  left: 30px;
  transition: 300ms;
  transform: translate();
  background-color: transparent;
}

.mlhbanner.hide {
  display: none;
}

.scroll {
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.5);
}

.menuNav {
  overflow-y: scroll;
  position: fixed;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.5);
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 27%; // 27% originally when Dashboard is included, +9% for any more links basically
  background-color: #173a52;
  width: 100%;
  overflow: hidden;
  transform: scaleY(1);
  transition: 200ms;
  z-index: 1;
}

.menuNav.showMenu {
  top: 4.875rem;
  z-index: -2;
}

.button {
  text-decoration: none;
  color: #ffffff;
  line-height: 50px;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 400;
  padding-bottom: 3px;
}

.bold_navitem {
  text-decoration: none;
  line-height: 50px;
  font-family: "Proxima Nova", sans-serif;
  padding-bottom: 3px;
  font-weight: 700;
  color: #9ee7e5;
  border-bottom: 2px solid #9ee7e5;
}

.button:active {
  color: #9ee7e5 !important;
}

.button:visited {
  color: #ffffff;
}

.bold_navitem:visited {
  color: #9ee7e5;
}

.exitDash {
  right: 2rem;
  top: 2rem;
}

.removeBg {
  position: absolute;
  height: 100vh;
  z-index: -3;
  background-color: grey;
  opacity: 100%;
}
