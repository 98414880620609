/* css reset */
* {
  vertical-align: baseline;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
  width: 100%;
  /*Note: fix this on merge later*/
  /* background-color: #173A52; */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* fonts */
/* @font-face {
  font-family: "Proxima Nova";
  font-weight: 700;
  src: url(./front-end/fonts/ProximaNova-Bold.otf) format("opentype");
} */

@font-face {
  font-family: "Proxima Nova";
  font-weight: 600;
  src: url(./front-end/fonts/Proxima\ Nova\ Semibold.ttf) format("truetype");
}

/* @font-face {
  font-family: "Proxima Nova";
  font-weight: 400;
  src: url(./front-end/fonts/ProximaNova-Regular.otf) format("opentype");
} */

@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap");

@font-face {
  font-family: 'Proxima Nova';
  src: url('./front-end/fonts/ProximaNova-Bold.eot');
  src: url('./front-end/fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('./front-end/fonts/ProximaNova-Bold.woff2') format('woff2'),
  url('./front-end/fonts/ProximaNova-Bold.woff') format('woff'),
  url('./front-end/fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("./front-end/fonts/ProximaNova-Medium.eot");
  src: url("./front-end/fonts/ProximaNova-Medium.eot?#iefix") format("embedded-opentype"), url("./front-end/fonts/ProximaNova-Medium.woff2") format("woff2"), url("./front-end/fonts/ProximaNova-Medium.woff") format("woff"), url("./front-end/fonts/ProximaNova-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./front-end/fonts/ProximaNova-Regular.eot');
  src: url('./front-end/fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('./front-end/fonts/ProximaNova-Regular.woff2') format('woff2'),
  url('./front-end/fonts/ProximaNova-Regular.woff') format('woff'),
  url('./front-end/fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
