.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 25px;
  max-width: 377px;
}

@media (min-width: 992px) {
  .container {
    max-width: 1330px;
  }
}
