@import "../../_defs";

.container {
  padding: 40px 25px;
  background-color: $darker_teal;
  margin-bottom: 24px;
  @include border();

  > p {
    width: 85%;
    @include body();
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    margin: auto;
    margin-bottom: 12px;
    > a {
      @include body();
      color: #45d5d9;
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
      text-decoration: none;
      padding-bottom: 3px;
      border-bottom: 2px solid transparent;
      transition: border-bottom 250ms ease-in-out;

      &:hover {
        border-bottom-color: #45d5d9;
        transition: border-bottom 250ms ease-in-out;
      }

      > img {
        width: auto;
      }
    }
  }

  > .info_flex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 15px;
    margin-bottom: 16px;
    > img {
      &:first-of-type {
        width: 20px;
        height: 20px;
      }

      &:last-of-type {
        width: 18px;
        height: 22px;
      }
    }
    > p {
      text-decoration: none;
      color: white;
      font-size: 20px;
      letter-spacing: 2%;
      font-family: "Proxima Nova", sans-serif;
      font-weight: 500;
      padding-bottom: 3px;
      
      border-bottom: 2px solid transparent;
      transition: border-bottom 250ms ease-in-out;
      
      > strong {
        text-decoration: none;
        color: white;
        font-size: 20px;
        letter-spacing: 2%;
        font-family: "Proxima Nova", sans-serif;
        font-weight: 700;
      }
      
      // > a {
      //   &:hover {
      //     border-bottom-color: white;
      //     transition: border-bottom 250ms ease-in-out;
      //   }
      // }
    }
    > h3 {
      @include body();
      font-size: 17px;
      line-height: 21px;
      margin: 0;

      > strong {
        @include header2();
        font-size: 17px;
        line-height: 21px;
        font-weight: 700;
      }
    }
  }
  .info_flex:first-of-type {
    > p {
      color: #9EE7E5;
      font-weight: bold;
      font-size: 26px;
      margin-bottom: 0;
    }
  }
  > .mentorVolunteer {
    width: 85%;
    font-family: "Proxima Nova", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: white;
    margin: auto;
    line-height: 19px;
    letter-spacing: 0.02em;
    > a {
      color: #9ee7e5;
      text-decoration: none;
      font-weight: 700;
      font-size: 18px;
      padding-bottom: 3px;
      border-bottom: 2px solid transparent;
      transition: border-bottom 250ms ease-in-out;

      &:hover {
        border-bottom-color: #9ee7e5;
        transition: border-bottom 250ms ease-in-out;
      }
    }
  }

  > .location_container {
    margin-bottom: 25px;
  }

  > h1 {
    @include title();
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  > div {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 26px;

    > button {
      &:first-of-type {
        @include button();
      }
      &:last-of-type {
        @include button(false);
      }
      padding: 7px;
      width: 100%;

      &:first-of-type {
        margin-bottom: 8px;
      }
    }
  }
}

@media (min-width: 992px) {
  .container {
    padding: 64px min(12%, 64px) 42px;

    > h1 {
      @include title(false);
      font-size: min(5rem, 6vw);
      margin-bottom: 32px;
    }

    > p {
      width: auto;

      > br {
        display: none;
      }
    }

    .info_flex {
      > p {
        margin-bottom: 0;
        > a {
          font-size: 20px;
        }
      }
      > h3 {
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.02rem;

        > strong {
          font-size: 24px;
        }

        &:last-of-type {
          line-height: 28px;
        }
      }
    }
    > .mentorVolunteer {
      width: auto;
      font-size: 20px;
      line-height: 24px;
    }
    > div {
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      > button {
        flex-grow: 1;
        width: auto;
        max-width: 202px;

        &:first-of-type {
          margin: 0;
          margin-right: 15px;
          // margin-left: auto;
          // margin-right: auto;
        }
      }
    }
    .hover_prereg {
      transition: 250ms ease-in-out;
    }
    .hover_sponsor {
      transition: 250ms ease-in-out;
    }
    .hover_prereg {
      &:hover {
        background-color: #45d5d9;
        border: 3px solid #45d5d9;
        transition: 250ms ease-in-out;
      }
      &:active {
        background-color: #45d5d9;
        border: 3px solid #45d5d9;
        transition: 250ms ease-in-out;
      }
    }
    .hover_sponsor {
      &:hover {
        border: 3px solid #45d5d9;
        color: #45d5d9;
        transition: 250ms ease-in-out;
      }
      &:active {
        border: 3px solid #45d5d9;
        color: #45d5d9;
        transition: 250ms ease-in-out;
      }
    }
  }
}

@media (min-width: 1240px) {
  .linebreak {
    display: none;
  }
}
