@import url("https://fonts.googleapis.com/css2?family=DM+Mono&display=swap");

@mixin header1($size: 32px, $line-height: 42px) {
  font-family: "DM Mono";
  font-weight: 500;
  font-size: $size;
  line-height: $line-height;
  letter-spacing: 0%;
  color: #9ee7e5;
}
@mixin header2($size: 14px) {
  font-family: "Proxima Nova";
  font-size: $size;
  letter-spacing: 2%;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 32px 24px;
  max-width: 1330px;
  margin: 24px auto;
  background-color: #173a52;
  border: solid #9ee7e5 3.5px;
  > a {
    text-decoration: none;
  }
}

.sponsors {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  a {
    display: block;
    height: auto;
    user-select: none;
    cursor: pointer;
    margin-bottom: 3rem;
  }

  .asucd {
    width: 65%;
    margin-inline: auto;
  }
}

.sponsors .matrix,
.mini a {
  margin-bottom: 4.5rem;
}

.contentWrapper {
  max-width: 990px;
}

.headerText {
  text-align: left;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: white;
  margin-bottom: 59px;
}

.box {
  width: 150px;
  height: 150px;
  background-color: blue;
}

.imageStyle {
  width: 100%;
  height: 100%;
  user-select: none;
  cursor: pointer;
}

@media only screen and (min-width: 992px) {
  .container {
    padding: 5rem;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .headerText {
    text-align: center;
  }
  .sponsors {
    a {
      margin: auto;
      display: block;
      transition: transform 0.3s ease-in-out;
      transform: scale(1);

      &:hover {
        transition: transform 0.3s ease-in-out;
        transform: scale(1.15);
      }
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: auto;
    }

    .break {
      flex-basis: 100%;
      height: 0;
      margin: 30px 0;
    }

    .ford {
      width: 624px;
      margin-bottom: 46px;
    }

    .twilio {
      width: 264px;
      margin-bottom: 60px;
    }

    .matrix {
      width: 300px;
      margin-bottom: 85px;
    }

    .citris {
      width: 22.3%;
      // max-width: 221px;
    }

    .eclipse {
      width: 26.7%;
      // max-width: 264px;
    }

    .gsec {
      width: 38.3%;
      // max-width: 379px;
    }

    .cls {
      width: 36.7%;
      // max-width: 364px;
    }

    .coe {
      width: 35.8%;
      // max-width: 354px;
    }

    .globalaffairs {
      width: 23.5%;
      // max-width: 233px;
    }

    .cs {
      width: 25.5%;
      // max-width: 252px;
    }

    .athletics {
      width: 17%;
      // max-width: 233px;
    }

    .msba {
      width: 37.6%;
      // max-width: 402px;
    }

    .iet {
      width: 28%;
      // max-width: 302px;
    }
    .datalab {
      width: 28%;
    }
    .cpe {
      width: 37.6%;
      // max-width: 372px;
    }

    .shds {
      width: 19.7%;
      // max-width: 195px;
    }

    .store {
      width: 30.3%;
      // max-width: 300px;
    }

    .asucd {
      width: 15.2%;
      // max-width: 150px;
    }

    .cfc {
      width: 25%;
      // max-width: 248px;
    }

    .visitdavis {
      width: 27%;
    }
    .yolo {
      width: 27%;
    }
    .reign {
      width: 27%;
    }
    .ultrapress {
      width: 27%;
    }
    .standout {
      width: 27%;
    }
    .kora {
      width: 26%;
    }
    .coolDavis {
      width: 20%;
    }
    .yoloFood {
      width: 30%;
    }
    .pantry{
      width: 23%;
    }
    .greenHouse {
      width: 25%;
    }
  }
}
