.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 24px 0px 0px auto;
  max-width: 1280px;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: -15px;
}

@media (min-width: 992px) {
  .container {
    width: 40%;
    right: 10px;
    max-width: 386px;
  }
}
